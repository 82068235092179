<template>
  <div>
    <div v-if="type === 'ENTER_INT'">
      <p>{{ valueInt }}</p>
    </div>
    <div v-if="type === 'ENTER_STRING'">
      <SExpandable :max-lines="2">
        <p>{{ valueStr }}</p>
      </SExpandable>
    </div>
    <div v-if="type === 'ENTER_DATE'">
      <p>{{ valueStr }}</p>
    </div>
    <div v-if="type === 'SELECT_ONE'">
      <p>{{ valueStr }}</p>
    </div>
    <div v-if="type === 'RATE'">
      <p v-if="valueInt < 0">
        Ответил анонимно
      </p>
      <div v-else-if="meta.type === 'smile'">
        <div class="d-flex pb-1">
          <VIcon
            :color="smile.color"
            size="14"
            class="pr-1"
          >
            {{ smile.activeIcon }}
          </VIcon>
          <p class="mb-0">
            {{ rateValue }}
          </p>
        </div>
      </div>
      <div
        v-else
        class="d-flex"
      >
        <p class="pr-1">
          {{ valueInt + ' / ' + meta.validate.max }}
        </p>
        <p>{{ rateValue }}</p>
      </div>
    </div>
    <div v-if="type === 'YES_NO'">
      <p class="pb-1">
        {{ valueInt ? 'Да' : 'Нет' }}
      </p>
    </div>
    <div v-if="type === 'SELECT_ONE'">
      <div
        v-for="(answer, index) in meta.answers"
        :key="answer.label"
      >
        <div
          v-if="index === valueInt"
          class="d-flex pb-1 align-baseline"
        >
          <VIcon
            :color="`${answer.is_right ? 'green':'red'}`"
            size="14"
            class="pr-1"
          >
            {{ answer.is_right ? 'fas fa-check-circle':'fas fa-times-circle' }}
          </VIcon>
          <p>{{ answer.label }}</p>
        </div>
      </div>
    </div>
    <div v-if="type === 'SELECT_MANY'">
      <div
        v-for="(answer, index) in meta.answers"
        :key="answer.label"
      >
        <div
          v-if="valueStr.includes(index)"
          class="d-flex pb-1 align-baseline"
        >
          <VIcon
            :color="`${answer.is_right ? 'green':'red'}`"
            size="14"
            class="pr-1"
          >
            {{ answer.is_right ? 'fas fa-check-circle':'fas fa-times-circle' }}
          </VIcon>
          <p>{{ answer.label }}</p>
        </div>
      </div>
    </div>
    <div v-if="type === 'VOTE'">
      <div v-if="vote">
        Ответил анонимно
      </div>
      <div
        v-for="(answer, index) in meta.answers"
        :key="answer.label"
      >
        <p v-if="valueStr.includes(index)">
          {{ answer.label }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { smiles } from '@/constants';
import SExpandable from '@/components/ui/SExpandable.vue';

export default {
  name: 'StaffTabAnswers',
  components: { SExpandable },
  props: {
    type: {
      type: String,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
    valueInt: {
      type: Number,
      required: true,
    },
    valueStr: {
      type: String,
      required: true,
    },
    isCorrect: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      smiles,
      DEFAULT_SMILE: 0,
    };
  },
  computed: {
    rateValue() {
      const rateValue = this.meta.action.text.find((_, index) => index === this.valueInt - 1);
      return rateValue;
    },
    smile() {
      const smile = this.smiles[this.valueInt - 1] || this.smiles[this.DEFAULT_SMILE];
      return smile;
    },
    vote() {
      const voteRes = this.meta.valueStr;
      const voteValues = voteRes ? voteRes.value : [];
      const voteValuesСondition = voteValues.length && voteValues[0] < 0;
      return voteValuesСondition;
    },
  },
};
</script>

<style scoped lang="scss">
/* stylelint-ignore no-empty-source */
</style>
