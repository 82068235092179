<template>
  <div
    class="uploader"
    :class="{ 'uploader_compact' : compact }"
  >
    <div>
      <VContainer
        ref="dropzone"
        data-test="drag-and-drop-upload"
        fluid
        class="pa-0 uploader__wrapper"
        :class="{
          'uploader__wrapper_dragover' : dragover,
          'uploader__wrapper_compact' : compact,
          'mb-3' : !compact
        }"
        @drop.prevent.stop="handleDrag"
        @dragover.prevent.stop="dragover = true"
        @dragleave.prevent.stop="dragover = false"
      >
        <template v-if="src">
          <VRow v-if="type === TYPES.IMAGE">
            <VCol class="py-0">
              <VImg
                class="background-img"
                :src="src"
              >
                <template #placeholder>
                  <VRow
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <VProgressCircular
                      indeterminate
                      color="grey"
                    />
                  </VRow>
                </template>
              </VImg>
            </VCol>
          </VRow>
          <template v-else-if="type === TYPES.VIDEO">
            <KinescopePlayer
              data-test="downloaded-video-player"
              class="kinescope-player"
              :video-id="src"
            />
          </template>
        </template>
        <template v-else-if="!loading">
          <VRow
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <div
              v-if="!errorMsg"
              class="text-center"
            >
              <VIcon
                v-if="type === TYPES.IMAGE"
                size="24"
                color="#8F9295"
              >
                fal fa-image
              </VIcon>
              <VIcon
                v-else-if="type === TYPES.VIDEO"
                size="24"
                color="#8F9295"
              >
                fal fa-video
              </VIcon>
              <div
                v-if="type === TYPES.IMAGE && !compact"
                class="tt-black--text text--lighten-2 mt-3"
                data-test="upload-image-form-text"
              >
                Перетащите файл JPEG, PNG или GIF<br>в это поле
              </div>
              <div
                v-else-if="type === TYPES.VIDEO && !compact"
                class="tt-text-body-1 tt-light-mono-46--text mt-4"
                data-test="upload-video-form-text"
              >
                Перетащите файл с видео для загрузки.
              </div>
            </div>
            <div
              v-else
              class="text-center"
            >
              <VIcon
                size="19"
                color="error"
              >
                fal fa-exclamation-circle
              </VIcon>
            </div>
          </VRow>
        </template>
        <template v-else>
          <VRow
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <VProgressCircular
              color="#0B1218"
              :class="{'cursor--pointer' : abort}"
              data-test="button-cancel-downloading"
              :value="progressPercent"
              :indeterminate="progressIndeterminate"
              :width="5"
              :size="46"
              @click.prevent="abortUpload"
            >
              <VIcon
                v-if="abort"
                color="tt-black"
                size="24"
              >
                fal fa-times
              </VIcon>
            </VProgressCircular>
          </VRow>
        </template>
      </VContainer>
    </div>
    <div>
      <div
        class="tt-text-body-2 tt-light-mono-46--text"
      >
        <template v-if="fileName">
          <span data-test="text-file-name">{{ fileName }}</span>
        </template>
        <template v-else-if="src">
          <span data-test="text-file-noname">{{ type === TYPES.IMAGE ? 'Изображение' : 'Видео' }}</span>
        </template>
        <template v-else>
          <span data-test="upload-text-max">{{ `Макс. размер - ${maxFileMb} МБ` }}</span>
        </template>
      </div>
      <template v-if="!errorMsg">
        <template v-if="!loading">
          <div
            v-if="!src"
            class="tt-text-body-2 cursor--pointer tt-ghost--text"
            data-test="button-upload-file"
            @click="onClick"
          >
            Загрузить
          </div>
          <div
            v-else
            class="tt-text-body-2 cursor--pointer error--text"
            data-test="button-delete"
            @click="onDelete"
          >
            <VIcon
              size="12"
              color="error"
              class="mr-1"
            >
              fal fa-trash-alt
            </VIcon>
            Удалить
          </div>
        </template>
        <div
          v-else
          class="tt-text-body-2 tt-light-mono-46--text"
          data-test="text-upload"
        >
          Загрузка...
        </div>
      </template>
      <template v-else>
        <div
          class="uploader__error"
          data-test="upload-error"
        >
          {{ errorMsg }}
          <span
            v-if="!compact"
            class="text--link cursor--pointer tt-ghost--text"
            data-test="button-repeat"
            @click="onClick"
          >Повторить</span>
          <span
            v-else
            class="text--link"
            data-test="button-repeat-other"
            @click="onClick"
          >Загрузить другой</span>
        </div>
      </template>
      <input
        v-show="false"
        ref="input"
        :accept="inputAccept"
        type="file"
        data-test="upload-image-input"
        @input="handleInput"
      >
      <div
        v-if="error"
        class="v-messages error--text mt-2"
        role="alert"
      >
        <div class="v-messages__wrapper">
          <div class="v-messages__message">
            {{ errorMessages }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toBase64, base64ToRaw } from '@/utils';
import { Upload } from 'tus-js-client';
import * as snamiApi from '@/services/backend/snami';
import { KinescopePlayer } from '@kinescope/vue-kinescope-player';

const TYPES = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
};
export default {
  name: 'Uploader',
  components: {
    KinescopePlayer,
  },
  props: {
    type: {
      type: String,
      validator: (v) => Object.values(TYPES).includes(v),
      default: TYPES.IMAGE,
    },
    value: {
      type: String,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array],
      default: () => ([]),
    },
    compact: {
      type: Boolean,
      default: false,
    },
    maxFileMb: {
      type: Number,
      default: 15,
    },
    reqParamsVideo: {
      type: Object,
      default: () => ({}),
    },
    showLoading: {
      type: Boolean,
      default: false,
    },
    progressIndeterminate: {
      type: Boolean,
      default: false,
    },
    abort: {
      type: Boolean,
      default: true,
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    dragAndDropCapable: false,
    dragover: false,
    loading: false,
    fileName: '',
    errorMsg: '',
    src: '',
    progressPercent: 0,
    kinescopeUpload: null,
    TYPES,
  }),
  computed: {
    inputAccept() {
      let accept = '';
      switch (this.type) {
        case this.TYPES.IMAGE:
          accept = 'image/jpeg,image/png,image/gif';
          break;
        case this.TYPES.VIDEO:
          accept = 'video/mp4,video/*';
          break;
        default:
      }
      return accept;
    },
    hasDeleteListener() {
      return this.$listeners && this.$listeners.delete;
    },
    hasSelectFileListener() {
      return this.$listeners && this.$listeners.selectFile;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if ((this.type === this.TYPES.IMAGE && value?.indexOf('http') !== -1)
          || this.type === this.TYPES.VIDEO
          || !value) {
          this.src = value;
        }
      },
    },
    src: {
      immediate: true,
      handler(src) {
        if (src?.indexOf('http') !== -1) {
          const file = new URL(src);
          this.fileName = decodeURI(file.pathname.split('/').pop());
        } else {
          this.fileName = '';
        }
      },
    },
    showLoading: {
      immediate: true,
      handler(loading) {
        this.loading = loading;
      },
    },
    progress: {
      immediate: true,
      handler(progress) {
        this.progressPercent = progress;
      },
    },
  },
  methods: {
    onClick() {
      this.$refs.input.click();
    },
    reset() {
      this.fileName = '';
      this.src = '';
      this.errorMsg = '';
      this.kinescopeUpload = null;
      this.progressPercent = 0;
      this.$emit('input', '');
    },
    async handleDrag(event) {
      const { dataTransfer } = event;
      if (dataTransfer.files.length > 0) {
        try {
          await this.handleFile(dataTransfer.files[0]);
        } catch (e) {
          console.warn(e);
        }
      }
    },
    async handleInput(event) {
      const { target } = event;
      if (target.files.length > 0) {
        try {
          await this.handleFile(target.files[0]);
        } catch (e) {
          console.warn(e);
        }
      } else {
        this.reset();
      }
    },
    async handleFile(file) {
      this.loading = true;
      this.reset();
      if (file.size > this.maxFileMb * 1024 * 1024) {
        this.serError(`Размер файла превышает ${this.maxFileMb} МБ.`);
        return;
      }
      if (this.hasSelectFileListener) {
        this.$emit('selectFile', file);
      } else {
        switch (this.type) {
          case this.TYPES.IMAGE:
            this.handleImage(file);
            break;
          case this.TYPES.VIDEO:
            this.handleVideo(file);
            break;
          default:
        }
      }
    },
    async handleImage(file) {
      if (!/\.(jpe?g|png|gif)$/i.test(file.name)) {
        this.serError('Неверный формат изображения');
        return;
      }
      const base64 = await toBase64(file);
      this.src = base64;
      this.$emit('input', base64ToRaw(base64));
      this.loading = false;
    },
    async handleVideo(file) {
      this.fileName = file.name;
      if (!/video/i.test(file.type)) {
        this.serError('Неверный формат видео');
        return;
      }
      try {
        const res = await snamiApi.videoUploadInit({
          title: file.name,
          file_name: file.name,
          file_size: file.size,
          ...this.reqParamsVideo,
        });
        const { data } = res;
        this.kinescopeUpload = new Upload(file, {
          uploadUrl: data?.upload_url,
          metadata: {
            filename: file.name,
            filetype: file.type,
          },
          onError: (err) => {
            this.serError('Не удалось загрузить файл');
            console.error(err);
          },
          onProgress: (bytesUploaded, bytesTotal) => {
            // eslint-disable-next-line
            const percentage = (bytesUploaded / bytesTotal * 100).toFixed(0);
            this.progressPercent = percentage;
          },
          onSuccess: () => {
            this.src = data?.video_id;
            this.$emit('input', data?.video_id);
            this.loading = false;
          },
        });
        this.kinescopeUpload.start();
      } catch (e) {
        this.serError('Не удалось загрузить файл');
        console.error(e);
      }
    },
    serError(err) {
      this.errorMsg = err;
      this.loading = false;
    },
    abortUpload() {
      if (!this.abort) {
        return;
      }
      if (this.type === this.TYPES.VIDEO && this.kinescopeUpload) {
        this.kinescopeUpload.abort();
        this.loading = false;
        this.reset();
      }
    },
    onDelete() {
      if (this.hasDeleteListener) {
        this.$emit('delete');
      } else {
        switch (this.type) {
          case TYPES.IMAGE:
            this.reset();
            break;
          case TYPES.VIDEO:
            if (this.value) {
              snamiApi.videoDelete(this.value)
                .catch((e) => {
                  console.error(e);
                });
            }
            this.reset();
            break;
          default:
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.uploader {
  width: 380px;
  padding: 16px;
  background: #FFFFFF;
  border: 1px dashed #EBECED;
  box-sizing: border-box;
  border-radius: 12px;
  flex-direction: column;
  &.uploader_compact{
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .kinescope-player  {
    width: 100%;
  }
  .uploader__wrapper {
    width: 100%;
    height: 230px;
    overflow: hidden;
    background-color: #F5F6F6;
    border: 1px solid #EBECED;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .uploader__wrapper .background-img{
    width: 100%;
    height: 230px;
  }
  .uploader__wrapper.uploader__wrapper_dragover {
    background-color: #EBECED;
  }
  .uploader__error {
    color: #EF323F;
  }
  .uploader__wrapper_compact{
    width: 110px;
    height: 110px;
    border-radius: 12px;
    margin-bottom: 0;
    margin-right: 16px;
  }
  .uploader__wrapper_compact .background-img{
    width: 110px;
    height: 110px;
  }
}
</style>
