<template>
  <tr>
    <td class="pr-3">
      <div class="mb-1">
        {{ item.name }}
      </div>
      <SExpandable
        :max-lines="2"
        class="tt-black--text text--lighten-2"
      >
        {{ item.description }}
      </SExpandable>
    </td>
    <td>
      <div>{{ formatDate(item.deadline) }}</div>
    </td>
    <td>
      <div>
        <span class="mr-1">{{ stateMap[item.state].text }}</span>
        <VIcon
          :color="stateMap[item.state].color"
          size="16"
          class="mx-1"
        >
          {{ stateMap[item.state].icon }}
        </VIcon>
      </div>
      <SExpandable
        :max-lines="2"
        class="tt-black--text text--lighten-2"
      >
        {{ item.comment }}
      </SExpandable>
    </td>
    <td>
      <template v-if="canBeRated">
        <template v-if="hasActor">
          <div>{{ rate }} из 5</div>
          <SExpandable
            :max-lines="2"
            class="tt-black--text text--lighten-2"
          >
            {{ item.actor.comment }}
          </SExpandable>
        </template>
        <template v-else>
          <p class="mb-1 tt-text-body-2">
            Ожидает оценки
          </p>
          <DialogWrapper
            v-model="dialog"
            width="380"
            @click:outside="dialog = false"
          >
            <template #activator="{ on }">
              <div
                class="tt-light-blue--text cursor--pointer"
                v-on="on"
              >
                Оценить
              </div>
            </template>
            <PurposeRateForm
              :purpose="item"
              @close="dialog=false"
              @save="handleSave"
            />
          </DialogWrapper>
        </template>
      </template>
      <div v-else>
        &mdash;
      </div>
    </td>
  </tr>
</template>

<script>
import find from 'lodash/find';
import { formatDate } from '@/utils';
import { purposeStates, purposeStateText } from '@/constants';
import PurposeRateForm from '@/components/forms/PurposeRateForm.vue';
import SExpandable from '@/components/ui/SExpandable.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';

export default {
  name: 'StaffPurposeItem',
  components: {
    DialogWrapper,
    PurposeRateForm,
    SExpandable,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      stateMap: {
        [purposeStates.FAILED]: {
          text: purposeStateText[purposeStates.FAILED],
          icon: 'fas fa-times-circle',
          color: '#FF3E00',
        },
        [purposeStates.COMPLETED]: {
          text: purposeStateText[purposeStates.COMPLETED],
          icon: 'fas fa-check-circle',
          color: '#00D358',
        },
        [purposeStates.REJECTED]: {
          text: purposeStateText[purposeStates.REJECTED],
          icon: 'fas fa-caret-circle-down',
          color: '#8F9295',
        },
        [purposeStates.NEW]: {
          text: purposeStateText[purposeStates.NEW],
          icon: 'fas fa-arrow-circle-right',
          color: '#FFB500',
        },
        [purposeStates.UNKNOWN]: {
          text: purposeStateText[purposeStates.UNKNOWN],
          icon: 'fas fa-question-circle',
          color: '#8F9295',
        },
      },
    };
  },
  computed: {
    hasActor() {
      return Boolean(this.item.actor);
    },
    rate() {
      if (this.hasActor) {
        return this.item.actor.rate;
      }
      return -1;
    },
    canBeRated() {
      const { state } = this.item;
      return state === purposeStates.COMPLETED || state === purposeStates.FAILED;
    },
  },
  methods: {
    formatDate,
    handleSave() {
      this.dialog = false;
      this.$emit('refresh');
    },
    getTask(id) {
      return find(this.tasks, (i) => i.id === id);
    },
  },
};
</script>

<style lang="scss">
.wraped-text {
  overflow-y: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

</style>
