var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTDataTable',{attrs:{"items":_vm.data,"headers":_vm.headers,"hide-default-footer":"","data-test":"staff-task-table","items-per-page":-1},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-test":"staff-tab-tasks-date"}},[_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"data-test":"staff-tab-tasks-name"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-test":"staff-tab-tasks-duration"}},[(item.duration_min)?[_vm._v(" "+_vm._s(item.duration_min)+" мин ")]:[_vm._v(" — ")]],2)]}},{key:"item.questions",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-test":"staff-tab-tasks-questions"}},[(item.question_count)?[_vm._v(" "+_vm._s(item.right_count)+"/"+_vm._s(item.question_count)+" ")]:_vm._e()],2)]}},{key:"item.bonuses",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-test":"staff-tab-tasks-bonuses"}},[_vm._v(" "+_vm._s(item.bonus)+"/"+_vm._s(item.total_bonus)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }