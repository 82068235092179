<template>
  <VRow>
    <VCol
      cols="12"
      sm="12"
    >
      <DialogWrapper
        v-model="showImage"
        content-class="media-image-gallery"
        @click:outside="closeModal"
      >
        <VImg
          :src="imageUrl"
          alt=""
          max-height="700"
          max-width="100%"
          width="auto"
          contain
          @click.stop="closeModal"
        />
      </DialogWrapper>
      <VCard elevation="0">
        <VContainer
          fluid
          data-test="staff-tab-media-containercontainer"
        >
          <VRow>
            <VCol
              v-for="n in data"
              :key="n.id"
              class="d-flex child-flex"
              cols="2"
              data-test="staff-tab-media-container-col"
              :data-test-label="n.id"
            >
              <VCard
                flat
                tile
                class="d-flex"
              >
                <VImg
                  :src="`${n.url}`"
                  :lazy-src="`${n.url}`"
                  aspect-ratio="1"
                  class="grey lighten-2 custom-media-box"
                >
                  <VCardTitle class="title white--text custom-media-icons-box justify-lg-space-around">
                    <TTBtn
                      color="transparent tt-ghost--text"
                      width="55"
                      height="55"
                      :href="n.url"
                      download
                      tag="a"
                      data-test-label="media-card-button-download"
                      aria-label="Скачать"
                    >
                      <VIcon size="24">
                        fal fa-cloud-download
                      </VIcon>
                    </TTBtn>
                    <TTBtn
                      color="transparent tt-ghost--text"
                      width="55"
                      height="55"
                      aria-label="Посмотреть"
                      data-test-label="media-card-button-viewing"
                      @click="setImage(n.url)"
                    >
                      <VIcon size="24">
                        fal fa-eye
                      </VIcon>
                    </TTBtn>
                  </VCardTitle>
                </VImg>
              </VCard>
            </VCol>
          </VRow>
        </VContainer>
      </VCard>
    </VCol>
  </VRow>
</template>

<script>
import DialogWrapper from '@/components/shared/DialogWrapper.vue';

export default {
  name: 'StaffTabMedia',
  components: { DialogWrapper },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showImage: false,
      imageUrl: '',
    };
  },
  methods: {
    closeModal() {
      this.showImage = false;
      this.imageUrl = '';
    },
    setImage(url) {
      this.showImage = true;
      this.imageUrl = url;
    },
  },
};
</script>

<style scoped lang="scss">
  .custom-media-icons-box {
    transition: background-color .4s ease-in-out;
    background-color: rgba(255,255,255, 0.7);
    width: 100%;
    height: 100%;
    display: none;
  }
  .custom-media-box{
    &:hover{
      .custom-media-icons-box{
        display: flex;
      }
    }
  }
</style>
