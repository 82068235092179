<template>
  <VContainer fluid>
    <VRow align="center">
      <template v-if="data.position">
        <VCol
          cols="2"
          class="text-right tt-text-body-2 tt-light-mono-46--text pt-0"
        >
          Должность
        </VCol>
        <VCol
          cols="10"
          class="pt-0"
        >
          <p
            class="tt-text-body-2 mb-0"
            data-test="staff-tab-info-position"
          >
            {{ data.position }}
          </p>
        </VCol>
      </template>
      <template v-if="data.email">
        <VCol
          cols="2"
          class="text-right tt-text-body-2 tt-light-mono-46--text pt-0"
        >
          Email
        </VCol>
        <VCol
          cols="10"
          class="pt-0"
        >
          <p
            class="tt-text-body-2 mb-0"
            data-test="staff-tab-info-email"
          >
            {{ data.email }}
          </p>
        </VCol>
      </template>
      <template v-if="data.phone">
        <VCol
          cols="2"
          class="text-right tt-text-body-2 tt-light-mono-46--text pt-0"
        >
          Мобильный тел.
        </VCol>
        <VCol
          cols="10"
          class="pt-0"
        >
          <p
            class="tt-text-body-2 mb-0"
            data-test="staff-tab-info-phone"
          >
            {{ data.phone }}
          </p>
        </VCol>
      </template>
      <VCol
        cols="2"
        class="text-right tt-text-body-2 tt-light-mono-46--text pt-0"
      >
        Дата выхода
      </VCol>
      <VCol
        cols="10"
        class="pt-0"
      >
        <p
          class="tt-text-body-2 mb-0"
          data-test="staff-tab-info-started"
        >
          {{ formatDate(data.started) }}
        </p>
      </VCol>
      <template v-if="data.birthday">
        <VCol
          cols="2"
          class="text-right tt-text-body-2 tt-light-mono-46--text pt-0"
        >
          День рождения
        </VCol>
        <VCol
          cols="10"
          class="pt-0"
        >
          <p
            class="tt-text-body-2 mb-0"
            data-test="staff-tab-info-birthday"
          >
            {{ formatDate(data.birthday) }}
          </p>
        </VCol>
      </template>
    </VRow>
    <VRow
      align="start"
      class="mt-4"
    >
      <template v-if="data.hr">
        <VCol
          cols="2"
          class="text-right tt-text-body-2 tt-light-mono-46--text pt-2"
        >
          HR
        </VCol>
        <VCol
          cols="10"
          class="pt-0"
        >
          <div
            class="d-flex align-center justify-start cursor--pointer"
            data-test="staff-tab-info-hr"
            @click="$router.push(`/staff/${data.hr.id || ''}`)"
          >
            <NewSAvatar
              data-test="staff-tab-info-hr-avatar"
              :img="data.hr.photo_url"
              :name="`${data.hr.name}`"
            />
            <div class="ml-3">
              <p
                class="tt-text-body-2 mb-0"
                data-test="staff-tab-info-hr-name"
              >
                {{ data.hr.name }}
              </p>
              <label
                v-if="data.hr.position"
                class="tt-text-body-2 tt-light-mono-46--text"
                data-test="staff-tab-info-hr-position"
              >{{ data.hr.position }}</label>
            </div>
          </div>
        </VCol>
      </template>
      <template v-if="data.mentor">
        <VCol
          cols="2"
          class="text-right tt-text-body-2 tt-light-mono-46--text pt-2"
        >
          Руководитель
        </VCol>
        <VCol
          cols="10"
          class="pt-0"
        >
          <div
            class="d-flex align-center justify-start cursor--pointer"
            data-test="staff-tab-info-mentor"
            @click="$router.push(`/staff/${data.mentor.id || ''}`)"
          >
            <NewSAvatar
              data-test="staff-tab-info-mentor-avatar"
              :img="data.mentor.photo_url"
              :name="`${data.mentor.name}`"
            />
            <div class="ml-3">
              <p
                class="tt-text-body-2 mb-0"
                data-test="staff-tab-info-mentor-name"
              >
                {{ data.mentor.name }}
              </p>
              <label
                v-if="data.mentor.position"
                class="tt-text-body-2 tt-light-mono-46--text"
                data-test="staff-tab-info-mentor-position"
              >{{ data.mentor.position }}</label>
            </div>
          </div>
        </VCol>
      </template>
      <template v-if="data.tutor">
        <VCol
          cols="2"
          class="text-right tt-text-body-2 tt-light-mono-46--text pt-2"
        >
          Наставник
        </VCol>
        <VCol
          cols="10"
          class="pt-0"
        >
          <div
            class="d-flex align-center justify-start cursor--pointer"
            data-test="staff-tab-info-tutor"
            @click="$router.push(`/staff/${data.tutor.id || ''}`)"
          >
            <NewSAvatar
              data-test="staff-tab-info-tutor-avatar"
              :img="data.tutor.photo_url"
              :name="`${data.tutor.name}`"
            />
            <div class="ml-3">
              <p
                class="tt-text-body-2 mb-0"
                data-test="staff-tab-info-tutor-name"
              >
                {{ data.tutor.name }}
              </p>
              <label
                v-if="data.tutor.position"
                class="tt-text-body-2 tt-light-mono-46--text"
                data-test="staff-tab-info-tutor-position"
              >{{ data.tutor.position }}</label>
            </div>
          </div>
        </VCol>
      </template>
    </VRow>
    <template v-if="showTagProfile">
      <VRow>
        <VCol>
          <VDivider />
        </VCol>
      </VRow>
      <VRow>
        <VCol>
          <h2
            class="tt-text-title-2"
            data-test="staff-tab-info-tags"
          >
            Теги
          </h2>
        </VCol>
      </VRow>
      <VRow
        dense
        class="mt-4"
      >
        <VCol cols="auto">
          <VChip
            label
            color="tt-light-blue pale"
            class="tt-light-blue--text"
            data-test="tag"
          >
            Юля и Лёшикуэ – топ
          </VChip>
        </VCol>
        <VCol cols="auto">
          <VChip
            label
            color="tt-light-blue pale"
            class="tt-light-blue--text"
            data-test="tag"
          >
            Дизайнер
          </VChip>
        </VCol>
        <VCol cols="auto">
          <VChip
            label
            color="tt-light-blue pale"
            class="tt-light-blue--text"
            data-test="tag"
          >
            Красотища
          </VChip>
        </VCol>
        <VCol cols="auto">
          <VChip
            label
            color="tt-light-blue pale"
            class="tt-light-blue--text"
            data-test="tag"
          >
            Какой-то тег
          </VChip>
        </VCol>
      </VRow>
    </template>
    <VRow>
      <VCol>
        <VDivider />
      </VCol>
    </VRow>
    <VRow>
      <VCol
        v-if="data.about"
        cols="12"
      >
        <h2 class="mb-4 tt-text-title-2">
          О себе
        </h2>
        <p
          class="tt-text-body-2"
          data-test="staff-tab-info-about"
        >
          {{ data.about }}
        </p>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { formatDate } from '@/utils';
import NewSAvatar from '@/components/ui/NewSAvatar.vue';

export default {
  name: 'StaffTabInfo',
  components: {
    NewSAvatar,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showTagProfile() {
      return this.$di.ff.get('profile_tags');
    },
  },
  methods: {
    formatDate,
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
