<template>
  <div
    v-resize="heightCalculate"
    class="expandable-text"
  >
    <div
      :style="{'height' : (more || !showMore) ?
        `${height}px` : `${lineHeight * maxLines}px`}"
      class="expandable-text__text"
    >
      <div ref="expandable">
        <slot />
      </div>
    </div>
    <div
      v-if="showMore"
      class="expandable-text__more"
      @click="more = !more"
    >
      {{ more? 'скрыть' : 'еще' }}
    </div>
  </div>
</template>
<script>

export default {
  name: 'SExpandable',
  props: {
    maxLines: {
      type: Number,
      default: 3,
    },
  },
  data: () => ({
    lineHeight: null,
    height: null,
    showMore: false,
    more: false,
  }),
  mounted() {
    this.heightCalculate();
  },
  methods: {
    calcLineHeight(el) {
      const div = document.createElement('div');
      const copyStyle = getComputedStyle(el);
      for (let i = 0; i < copyStyle.length; i += 1) {
        if (copyStyle[i].indexOf('font') !== -1 || copyStyle[i].indexOf('line') !== -1) {
          div.style[copyStyle[i]] = copyStyle[copyStyle[i]];
        }
      }
      div.textContent = 'Any';
      document.body.appendChild(div);
      const { offsetHeight } = div;
      document.body.removeChild(div);
      return offsetHeight;
    },
    heightCalculate() {
      if (this.$refs.expandable) {
        const { expandable } = this.$refs;
        this.lineHeight = this.calcLineHeight(expandable);
        this.height = expandable.offsetHeight;
        if (this.height >= this.lineHeight * (this.maxLines + 1)) {
          this.showMore = true;
        }
      }
    },
  },
};
</script>

<style lang="scss">
  .expandable-text {
    .expandable-text__text {
      overflow: hidden;
      height: auto;
      transition: height 0.5s;
    }
    .expandable-text__more {
      text-decoration: none;
      cursor: pointer;
      color: #00A0F2;
    }
  }
</style>
