<template>
  <TTDataTable
    :items="filteredPurposes"
    :headers="headers"
    hide-default-footer
    data-test="staff-purposes-table"
    :items-per-page="-1"
  >
    <template #header.state="{ }">
      <TTSelect
        v-model="stateFilter"
        :items="stateList"
        dense
        :attach="false"
        class="state-filter mt-0"
      >
        <template #selection="{ item }">
          <div
            class="tt-black--text text--lighten-2 tt-text-body-2"
          >
            {{ item.text }}
          </div>
        </template>
      </TTSelect>
    </template>
    <template #item="{item}">
      <StaffPurposeItem
        :key="item.id"
        :item="item"
        @refresh="$emit('refresh')"
      />
    </template>
  </TTDataTable>
</template>

<script>
import find from 'lodash/find';
import { formatDate } from '@/utils';
import { purposeStates, purposeStateText } from '@/constants';
import StaffPurposeItem from '@/components/staff/StaffPurposeItem.vue';

export default {
  name: 'StaffTabPurposes',
  components: { StaffPurposeItem },
  props: {
    data: {
      type: Array,
      required: true,
    },
    tasks: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      stateFilter: '',
      stateList: [
        { text: 'Все статусы', value: '' },
        { text: purposeStateText[purposeStates.COMPLETED], value: purposeStates.COMPLETED },
        { text: purposeStateText[purposeStates.REJECTED], value: purposeStates.REJECTED },
        { text: purposeStateText[purposeStates.NEW], value: purposeStates.NEW },
        { text: purposeStateText[purposeStates.FAILED], value: purposeStates.FAILED },
      ],
      headers: [
        {
          text: 'Цель',
          value: 'target',
          width: '40%',
          sortable: false,
        },
        {
          text: 'Дата окончания',
          value: 'deadline',
          width: '18%',
          sortable: false,
        },
        {
          text: 'Статус',
          value: 'state',
          width: '21%',
          sortable: false,
        },
        {
          text: 'Оценка',
          value: 'grade',
          width: '21%',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    filteredPurposes() {
      if (!this.stateFilter) return this.data;
      return this.data.filter((item) => item.state === this.stateFilter);
    },
  },
  methods: {
    formatDate(date) {
      return formatDate(date, 'DD.MM.YYYY hh:mm');
    },
    getTask(id) {
      return find(this.tasks, (i) => i.id === id);
    },
    calcLineHeight(el) {
      const div = document.createElement('div');
      const copyStyle = getComputedStyle(el);
      for (let i = 0; i < copyStyle.length; i += 1) {
        if (copyStyle[i].indexOf('font') !== -1 || copyStyle[i].indexOf('line') !== -1) {
          div.style[copyStyle[i]] = copyStyle[copyStyle[i]];
        }
      }
      div.textContent = 'Any';
      document.body.appendChild(div);
      const { offsetHeight } = div;
      document.body.removeChild(div);
      return offsetHeight;
    },

  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
