<template>
  <div>
    <TTDataTable
      :items="data"
      :headers="headers"
      hide-default-footer
      data-test="staff-task-table"
      :items-per-page="-1"
    >
      <template #item.date="{item}">
        <div data-test="staff-tab-tasks-date">
          {{ formatDate(item.date) }}
        </div>
      </template>
      <template #item.name="{item}">
        <span data-test="staff-tab-tasks-name">
          {{ item.name }}
        </span>
      </template>
      <template #item.duration="{item}">
        <div
          data-test="staff-tab-tasks-duration"
        >
          <template v-if="item.duration_min">
            {{ item.duration_min }} мин
          </template>
          <template v-else>
            —
          </template>
        </div>
      </template>
      <template #item.questions="{item}">
        <div
          data-test="staff-tab-tasks-questions"
        >
          <template v-if="item.question_count">
            {{ item.right_count }}/{{ item.question_count }}
          </template>
        </div>
      </template>
      <template
        #item.bonuses="{item}"
      >
        <div data-test="staff-tab-tasks-bonuses">
          {{ item.bonus }}/{{ item.total_bonus }}
        </div>
      </template>
    </TTDataTable>
  </div>
</template>

<script>
import { formatDate } from '@/utils';

export default {
  name: 'StaffTabTasks',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Дата',
          value: 'date',
        },
        {
          text: 'Название',
          value: 'name',
          width: '30%',
        },
        {
          text: 'Длительность',
          value: 'duration',
        },
        {
          text: 'Вопросы',
          value: 'questions',
        },
        {
          text: 'Баллы',
          value: 'bonuses',
        },
      ],
    };
  },
  methods: {
    formatDate,
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
н
