<template>
  <div
    :class="{'s-quill' : true, 's-quill--error' : error}"
  >
    <div class="s-quill__slot">
      <slot :handlers="{insertContent}" />
    </div>
    <div id="toolbar">
      <span class="ql-formats">
        <select
          data-test="seditor-header"
          class="ql-header"
        >
          <option value="1" />
          <option value="2" />
          <option selected />
        </select>
      </span>
      <span class="ql-formats">
        <button
          data-test="seditor-bold"
          class="ql-bold"
        />
      </span>
      <span class="ql-formats">
        <button
          data-test="seditor-list-ordered"
          class="ql-list"
          value="ordered"
        />
      </span>
      <span class="ql-formats">
        <button
          data-test="seditor-list-bullet"
          class="ql-list"
          value="bullet"
        />
      </span>
      <span class="ql-formats">
        <button
          data-test="seditor-link"
          class="ql-link"
        />
      </span>
      <span class="ql-formats">
        <button
          data-test="seditor-clean"
          class="ql-clean"
        />
      </span>
      <span class="ql-formats">
        <button
          data-test="seditor-emoji"
          class="ql-emoji"
          @click="addDataTestEmoji"
        />
      </span>
      <span
        v-if="image"
        class="ql-formats"
      >
        <button
          data-test="seditor-image"
          class="ql-image"
        />
      </span>
    </div>
    <div
      ref="editor"
      data-test="text-editor"
      :data-test-label="dataTestLabel"
      :style="{height : `${height}px`}"
      class="s-quill__editor"
    />
    <div
      v-if="error"
    >
      <div class="error--text">
        {{ errorMessages }}
      </div>
    </div>
  </div>
</template>

<script>
import Quill from 'quill';
import Emoji from 'quill-emoji/dist/quill-emoji';

Quill.register({
  'formats/emoji': Emoji.EmojiBlot,
  'modules/short_name_emoji': Emoji.ShortNameEmoji,
  'modules/toolbar_emoji': Emoji.ToolbarEmoji,
  'modules/textarea_emoji': Emoji.TextAreaEmoji,
}, true);

const defaultOpt = {
  debug: false,
  modules: {
    toolbar: '#toolbar',
    'emoji-toolbar': true,
    // 'emoji-textarea': true,
    'emoji-shortname': true,
  },
  readOnly: false,
  theme: 'snow',
};

export default {
  name: 'SEditor',
  props: {
    height: {
      type: String,
      default: '500',
    },
    opts: { type: Object, default: () => defaultOpt },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: String,
      default: '',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    classH1: {
      type: String,
      default: '',
    },
    classH2: {
      type: String,
      default: '',
    },
    image: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      quill: null,
    };
  },
  mounted() {
    this.init();
    this.quill.on('text-change', () => {
      this.$emit('text-change');
    });
  },
  beforeDestroy() {
    this.quill.off('text-change');
    this.quill = null;
    delete this.quill;
  },
  methods: {
    init() {
      const mergedOpts = {
        ...defaultOpt,
        ...this.opts,
        bounds: this.$refs.editor,
      };
      this.quill = new Quill(this.$refs.editor, mergedOpts);
      this.addDataTests();
    },
    getHtml() {
      this.addClassHeader(this.classH1, 'h1');
      this.addClassHeader(this.classH2, 'h2');
      const html = this.quill.root.innerHTML;
      if (html === '<p><br></p>') {
        return '';
      }
      return html;
    },
    getSelection() {
      return this.quill.getSelection();
    },
    insertText(pos, value) {
      return this.quill.insertText(pos, value);
    },
    insertContent(value) {
      this.quill.focus();
      return this.quill.insertText(this.quill.getSelection(), value);
    },
    insertRawHtml(value) {
      this.quill.root.innerHTML = value;
    },
    addClassHeader(className, tag = 'h1') {
      if (className) {
        const headers = this.quill.root.querySelectorAll(tag);
        [...headers].forEach((header) => {
          header.classList.add(className);
        });
      }
    },
    addDataTests() {
      try {
        const optionsEl = document.getElementsByClassName('ql-picker-options');
        const tooltioEl = document.getElementsByClassName('ql-tooltip');
        if (optionsEl.length) {
          optionsEl[0].setAttribute('data-test', 'seditor-picker-options');
        }
        if (tooltioEl.length) {
          tooltioEl[0].setAttribute('data-test', 'seditor-tooltip');
        }
      } catch (e) {
        console.error(e);
      }
    },
    addDataTestEmoji() {
      try {
        setTimeout(() => {
          const emojiEl = document.getElementById('emoji-palette');
          if (emojiEl) {
            emojiEl.setAttribute('data-test', 'seditor-emoji-palette');
          }
        }, 10);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss">
  .s-quill{
    width:100%;
    li{
      &:before{
        width: 2em;
      }
    }
    &--error{
      .ql-toolbar{
        border-top-color: #EF323F!important;
        border-left-color: #EF323F!important;
        border-right-color: #EF323F!important;
      }
      .s-quill__editor{
        border-left-color: #EF323F!important;
        border-right-color: #EF323F!important;
        border-bottom-color: #EF323F!important;
      }
    }
  }

  .s-quill .ql-toolbar,
  .s-quill .s-quill__editor {
    background-color: #F5F6F6;
    border: 1px solid #EBECED;
  }

  .s-quill .ql-toolbar {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .s-quill .s-quill__editor {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
</style>
