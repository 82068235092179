<template>
  <VContainer class="pa-0">
    <VRow>
      <VCol>
        <h2 data-test="replace-staff-form-replace-title">
          {{ title }}
        </h2>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <p class="ma-0">
          Выберите сотрудника, на которого будут перенесены все связи пользователя в качестве HR,
          наставника и руководителя, а также перезаписан элемент Контакт.
        </p>
      </VCol>
    </VRow>
    <VRow>
      <VCol
        cols="12"
      >
        <TTAutocomplete
          label="Сотрудник"
          no-rotate
          large
          :value="replaceId"
          :loading="autocompleteLoading"
          :items="staffList"
          :search-input.sync="search"
          hide-no-data
          hide-details="auto"
          append-icon="fal fa-search"
          placeholder="Выберите сотрудника"
          data-test="replace-staff-form-replace-autocomplete"
          :error="validation('replace_staff_id').status"
          :error-messages="validation('replace_staff_id').value"
          @input="handleChange('replaceId', $event)"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <div class="d-flex justify-end w-100 align-content-center">
          <TTBtn
            large
            color="tt-ghost--text"
            data-test="replace-staff-form-button-cancel"
            @click="handleClose"
          >
            Отменить
          </TTBtn>
          <TTBtn
            large
            depressed
            :loading="loading"
            data-test="replace-staff-form-button-submit"
            @click="handleReplace"
          >
            Заменить
          </TTBtn>
        </div>
      </VCol>
    </VRow>
    <TTBtn
      absolute
      right
      top
      data-test="replace-staff-form-button-close"
      width="20"
      height="20"
      min-width="20"
      class="cursor--pointer pa-0 delete-form__cancel-icon"
      depressed
      color="transparent"
      @click="handleClose"
    >
      <VIcon
        color="tt-black"
        size="19"
      >
        fal fa-times
      </VIcon>
    </TTBtn>
  </VContainer>
</template>

<script>
import { handleError, validation, watchValidationStatus } from '@/services';

export default {
  name: 'ReplaceStaffForm',
  props: {
    staff: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hasNetwork: false,
      autocompleteLoading: false,
      staffList: [],
      search: null,
      loading: false,
      replaceId: null,
      validationStatus: [],
      errorMessages: '',
    };
  },
  computed: {
    staffId() {
      return this.staff.id;
    },
    title() {
      return `Заменить сотрудника ${this.staff.first_name} ${this.staff.last_name}?`;
    },
  },
  watch: {
    search: {
      handler(val) {
        if (val) {
          this.handleSearch(val);
        } else {
          this.staffList = [];
        }
      },
    },
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  methods: {
    validation,
    handleError,
    async handleReplace() {
      try {
        this.loading = true;
        if (!this.staffId || !this.replaceId) {
          this.loading = false;
          return;
        }
        const data = {
          id: this.staffId,
          replace_staff_id: this.replaceId,
        };
        await this.$repositories.staff.replaceNetwork({ data });
        this.$emit('replace', this.replaceId);
      } catch (e) {
        this.handleError(e);
        this.$root.$emit('showNotifier', {
          title: 'Не удалось заменить связи',
          timeout: 6000,
          type: 'error',
        });
      } finally {
        this.loading = false;
      }
    },
    handleSearch(text) {
      this.autocompleteLoading = true;
      this.$repositories.staff.select({
        data: {
          filter: {
            location_id: null,
            is_fired: false,
            search_text: text,
          },
          page_size: 20,
        },
      })
        .then((r) => {
          const { data: { data: staff } } = r;
          this.staffList = [];
          staff.forEach((item) => {
            if (item.id !== this.staff.id) {
              this.staffList.push({
                text: item.name,
                value: item.id,
              });
            }
          });
        })
        .catch((e) => {
          console.warn(e);
        })
        .finally(() => {
          this.autocompleteLoading = false;
        });
    },
    handleClose() {
      this.replaceId = null;
      this.$emit('cancel');
    },
    handleChange(name, value, target) {
      this[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
