<template>
  <VContainer
    fluid
    class="pa-0"
  >
    <VRow>
      <VCol
        cols="12"
      >
        <h2 class="mb-4 tt-text-title-2">
          Оценка цели
        </h2>
        <h4 class="mb-3 tt-text-body-2">
          Оцените результат цели
        </h4>
        <div class="d-flex justify-space-between mb-2">
          <div
            v-for="(btn, i) in smiles"
            :key="i"
            class="d-flex flex-column align-start tt-black--text text--lighten-2"
          >
            <TTBtn
              :color="i + 1 === form.rate ? btn.color: 'secondary'"
              depressed
              @click="handleChange('rate', i + 1)"
            >
              <VIcon
                :color="i + 1 === form.rate ? 'white' : btn.color"
                size="19"
              >
                {{ btn.icon }}
              </VIcon>
            </TTBtn>
            <div
              v-if="i + 1 === form.rate"
              class="mt-2 hint"
            >
              <span
                class="tt-text-body-2 tt-light-mono-100--text hint__text"
              >
                {{ btn.text }}
              </span>
            </div>
          </div>
        </div>
        <div
          v-if="form.rate === null"
          class="d-flex justify-space-between mt-2"
        >
          <span class="tt-text-body-2 tt-light-mono-46--text">
            Очень плохо
          </span>
          <span class="tt-text-body-2 tt-light-mono-46--text">
            Отлично
          </span>
        </div>
        <div
          v-if="validation('rate').status"
          class="v-messages error--text mt-2"
          role="alert"
        >
          <div class="v-messages__wrapper">
            <div class="v-messages__message">
              {{ validation('rate').value }}
            </div>
          </div>
        </div>
      </VCol>
      <VCol cols="12">
        <VDivider />
      </VCol>
      <VCol
        cols="12"
        class="pt-1"
      >
        <TTTextarea
          :maxlength="10000"
          :value="form.comment"
          auto-grow
          :rows="3"
          single-line
          label="Комментарий"
          hide-details="auto"
          placeholder="Введите комментарий..."
          data-test="textarea-comment"
          :error="validation('comment').status"
          :error-messages="validation('comment').value"
          @input="v => handleChange('comment',v)"
        />
        <div class="tt-text-body-2 tt-light-mono-46--text mt-2">
          Сотрудник сможет прочитать комментарий
          в приложении.
        </div>
      </VCol>
      <VCol
        cols="12"
        align="right"
        class="pb-3"
      >
        <TTBtn
          color="tt-ghost--text"
          @click="handleClose"
        >
          Отменить
        </TTBtn>
        <TTBtn
          class="ml-6"
          @click="handleSave"
        >
          Оценить
        </TTBtn>
      </VCol>
    </VRow>
  </vcontainer>
</template>

<script>
import * as snamiApi from '@/services/backend/snami';
import { validation, handleError, watchValidationStatus } from '@/services';
import { smiles } from '@/constants';

export default {
  name: 'PurposeRateForm',
  props: {
    purpose: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    validationStatus: [],
    form: {
      rate: null,
      comment: '',
      id: null,
    },
    smiles,
  }),
  watch: {
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  created() {
    this.form.id = this.purpose.id;
  },
  methods: {
    validation,
    handleError,
    handleChange(name, value, target) {
      this.form[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
    handleClose() {
      this.$emit('close');
    },
    handleSave() {
      snamiApi.ratePurpose(this.form)
        .then(() => {
          this.$emit('save');
        })
        .catch((e) => this.handleError(e));
    },
  },
};
</script>

<style scoped lang="scss">
.hint {
  position: relative;
  height: 11px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  &__text {
    position: absolute;
    text-align: center;
    width: 100px;
  }
}
</style>
