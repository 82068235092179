<template>
  <VContainer
    fluid
    class="pa-0"
  >
    <DialogWrapper
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @click:outside="dialog = false"
    >
      <StaffForm
        v-if="dialog"
        :edit="true"
        :edit-user="staff"
        @close="dialog = false"
        @save="handleSave"
      />
    </DialogWrapper>

    <VRow v-if="error">
      <VCol data-test="staff-item-error">
        {{ error }}
      </VCol>
    </VRow>
    <template v-else-if="!loading">
      <VRow
        class="justify-start"
      >
        <VCol
          class="d-flex align-start"
          cols="10"
        >
          <div class="ava mr-5">
            <NewSAvatar
              :size="'xl'"
              data-test="staff-item-avatar"
              :img="staff.photo_url"
              :name="`${staff.last_name} ${staff.first_name}`"
            />
          </div>
          <div>
            <h1
              class="tt-text-headline-1 mb-2"
              data-test="staff-item-full-name"
            >
              {{ staff.last_name }} {{ staff.first_name }} {{ staff.middle_name || '' }}
            </h1>
            <label
              v-if="staff.position"
              class="tt-black--text text--lighten-2"
              data-test="staff-item-position"
            >
              {{ staff.position }}
            </label>
          </div>
        </VCol>
        <VSpacer />
        <VCol
          class="text-right"
          cols="2"
        >
          <div
            v-if="staff.progress > 0"
            class="mb-3"
          >
            <VProgressCircular
              rotate="270"
              :size="50"
              :value="staff.progress"
              color="primary"
              data-test="staff-item-level-count"
            >
              {{ staff.level }}
            </VProgressCircular>
            <p
              class="tt-text-caption tt-light-mono-46--text mt-2 mb-3"
              data-test="staff-item-text-level"
            >
              Уровень
            </p>
          </div>
          <div v-if="tasks.length">
            <p
              class="tt-text-body-2 mb-0"
              data-test="staff-item-last_request"
            >
              {{ formatDate(staff.last_request, 'DD.MM.YYYY') }}
            </p>
            <p
              class="tt-text-caption tt-light-mono-46--text"
              data-test="staff-item-text-last-active"
            >
              Последняя активность
            </p>
          </div>
        </VCol>
      </VRow>
      <VDivider class="mt-6 mb-3" />
      <VRow class="pa-0">
        <VCol
          v-if="!loading"
          flat
          class="py-0 col-12"
        >
          <VTabs
            v-model="currentItem"
            slider-color="white"
            color="#000"
            active-class="active-tab-background"
            data-test="staff-item-tabs"
          >
            <VTabsSlider color="#000" />
            <VTab
              v-for="item in tabNames"
              :key="item.text"
              :data-test="`staff-item-tab-` + item.value"
              :href="'#tab-' + item.value"
            >
              {{ item.text }}
            </VTab>
            <VTab
              v-if="showNetwork"
              key="network"
              data-test="staff-item-tab-network"
              href="#tab-network"
            >
              Связи
            </VTab>
          </VTabs>
          <VTabsItems
            v-model="currentItem"
          >
            <VTabItem
              v-for="item in tabNames"
              :key="item.text"
              :data-test="'staff-item-tab-content-' + item.value"
              :transition="false"
              :value="'tab-' + item.value"
            >
              <VCard
                flat
                class="mt-6"
              >
                <Component
                  :is="componentsMap[item.value]"
                  data-test="staff-item-tab-content-components"
                  :data="get($data, item.path)"
                  :tasks="tasks"
                  @refresh="getStaffItem"
                />
              </VCard>
            </VTabItem>
            <VTabItem
              v-if="showNetwork"
              key="network"
              data-test="staff-item-tab-content"
              :transition="false"
              value="tab-network"
            >
              <VCard
                flat
                class="mt-6"
              >
                <StaffTabNetwork
                  :data="staff"
                />
              </VCard>
            </VTabItem>
          </VTabsItems>
        </VCol>
      </VRow>
    </template>
  </VContainer>
</template>

<script>
import get from 'lodash/get';
import { formatDate, pluralize } from '@/utils';
import NewSAvatar from '@/components/ui/NewSAvatar.vue';
import StaffForm from '@/components/forms/StaffForm.vue';
import StaffTabInfo from '@/components/staff/StaffTabInfo.vue';
import StaffTabMedia from '@/components/staff/StaffTabMedia.vue';
import StaffTabResults from '@/components/staff/StaffTabResults.vue';
import StaffTabEvents from '@/components/staff/StaffTabEvents.vue';
import StaffTabTasks from '@/components/staff/StaffTabTasks.vue';
import StaffTabPurposes from '@/components/staff/StaffTabPurposes.vue';
import StaffTabNetwork from '@/components/staff/StaffTabNetwork.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';

export default {
  name: 'StaffItem',
  components: {
    DialogWrapper,
    StaffForm,
    StaffTabMedia,
    StaffTabInfo,
    StaffTabResults,
    StaffTabEvents,
    StaffTabTasks,
    StaffTabPurposes,
    StaffTabNetwork,
    NewSAvatar,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      staff: {},
      tasks: [],
      results: [],
      media: [],
      purposes: [],
      events: [],
      error: '',
      currentItem: 0,
      componentsMap: {
        info: 'StaffTabInfo',
        tasks: 'StaffTabTasks',
        results: 'StaffTabResults',
        events: 'StaffTabEvents',
        media: 'StaffTabMedia',
        purposes: 'StaffTabPurposes',
      },
      tabNames: [
        {
          text: 'Информация',
          path: 'staff',
          value: 'info',
        },
        {
          text: 'Задачи',
          path: 'tasks',
          value: 'tasks',
        },
        {
          text: 'Ответы',
          path: 'results',
          value: 'results',
        },
        {
          text: 'События',
          path: 'events',
          value: 'events',
        },
        {
          text: 'Медиа',
          path: 'media',
          value: 'media',
        },
        {
          text: 'Цели',
          path: 'purposes',
          value: 'purposes',
        },
      ],
    };
  },
  computed: {
    showNetwork() {
      return this.staff.has_network;
    },
  },
  watch: {
    $route: {
      handler() {
        this.getStaffItem();
      },
      immediate: true,
    },
    $router: {
      handler() {
        if (this.$route.hash.includes('#tab=network')) {
          this.currentItem = 'tab-network';
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$root.$on('editUser', this.editUser);
  },
  beforeDestroy() {
    this.$root.$off('editUser', this.editUser);
  },
  methods: {
    get,
    formatDate,
    staffAge() {
      if (!this.staff.birthday) return '';
      const date = this.$dayjs(this.staff.birthday);
      const diff = Date.now() - date.toDate().getTime();
      const ageDate = new Date(diff);
      const age = Math.abs(ageDate.getUTCFullYear() - 1970);
      return `${age} ${pluralize(age, 'год', 'года', 'лет')}`;
    },
    editUser() {
      if (!this.loading) {
        this.dialog = true;
      }
    },
    handleSave() {
      this.dialog = false;
      this.getStaffItem();
    },
    clearData() {
      this.staff = {};
      this.tasks = [];
      this.results = [];
      this.purposes = [];
      this.media = [];
      this.events = [];
    },
    async getStaffItem() {
      try {
        this.loading = true;
        this.clearData();
        const { currentItem } = this;

        const { data: { data } } = await this.$repositories.staff.get(this.$route.params.id);
        if (data) {
          this.events = data.event_results;
          this.staff = data.staff;
          this.tasks = data.tasks;
          this.results = data.results;
          this.purposes = data.purposes;
          this.media = data.media;
          if (this.$route.hash.includes('#tab')) {
            const routeHash = this.$route.hash.split('=');
            this.currentItem = `tab-${routeHash[1]}`;
          }
          this.currentItem = currentItem;
        }
      } catch (e) {
        const { response } = e;
        if (response.status === 403 && response.data.error.code === 'ACCESS_DENIED') {
          this.error = response.data.error.message;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
  .ava {
    position: relative;
  }
  .progress-percent {
    font-size: 14px;
    border-radius: 14px;
    background-color: #ececed;
    padding: 3px 8px;
    font-weight: 600;
    margin-left: 46px;
    margin-top: -14px;
    border: none;
    z-index: 2;
    align-self: flex-end;
    position: absolute;
  }
  /* stylelint-ignore no-empty-source */
</style>
