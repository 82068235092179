<template>
  <VContainer
    fluid
    class="staff-tab-network px-0 pt-0"
  >
    <DialogWrapper
      v-model="contentDialog"
      fullscreen
      @click:outside="closeContentModal"
    >
      <TaskContentForm
        v-if="contentDialog"
        :location="{ id : task.location_id }"
        :task="task"
        :level-n="level ? level.n : 0"
        :edit="level ? !level.visible : true"
        :edit-entity="activeEntity"
        @close="closeContentModal"
        @save="handleSave"
      />
    </DialogWrapper>
    <DialogWrapper
      v-model="staffDialog"
      fullscreen
      @click:outside="closeStaffModal"
    >
      <StaffForm
        v-if="staffDialog"
        :edit="true"
        :edit-user="activeEntity"
        @close="closeStaffModal"
        @save="handleSave"
      />
    </DialogWrapper>
    <DialogWrapper
      v-model="replaceDialog"
      width="536"
    >
      <ReplaceStaffForm
        v-if="replaceDialog"
        :staff="data"
        @replace="handleReplace"
        @cancel="replaceDialog = false"
      />
    </DialogWrapper>

    <VRow v-if="loading">
      <VCol align="center">
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VCol>
    </VRow>
    <template v-else>
      <VRow>
        <VCol class="pb-0">
          <div class="d-flex align-center">
            <span class="tt-text-body-2">Все роли связи можно перенести на другого сотрудника.</span>
            <TTBtn
              class="px-1"
              data-test="change-staff"
              text
              @click="replaceDialog = true"
            >
              Перенести
            </TTBtn>
          </div>
        </VCol>
      </VRow>
      <VRow>
        <VCol>
          <TTDataTable
            :items="network"
            :headers="headers"
            :items-per-page="-1"
            no-data-text="У пользователя отсутствуют связи"
          >
            <template #header.role="{}">
              <div v-if="filterBy.length <= 2">
                У сотрудника в роли
              </div>
              <div
                v-else
                class="staff-tab-network__select"
              >
                <TTSelect
                  v-model="filter"
                  :items="filterBy"
                  dense
                  :attach="false"
                  placeholder="Выбор роли"
                  class="state-filter mt-0"
                  data-test="filter-role"
                >
                  <template #selection="{ item }">
                    <div
                      class="tt-black--text text--lighten-2 tt-text-body-2"
                    >
                      {{ item }}
                    </div>
                  </template>
                </TTSelect>
              </div>
            </template>
            <template #item.name="{item}">
              <div
                v-if="item.type === 'staff'"
                class="d-flex align-center cursor--pointer"
                data-test="staff-link"
                @click="$router.push(`/staff/${item.staff.id}`)"
              >
                <NewSAvatar
                  url-key="photo_url"
                  :item="item"
                  :size="'sm'"
                  data-test="staff-link-avatar"
                  :name="`${item.staff.last_name} ${item.staff.first_name}`"
                />
                <div class="ml-3">
                  <div class="text--one-line">
                    {{ item.staff.last_name }} {{ item.staff.first_name }}
                  </div>
                  <label class="tt-black--text text--lighten-2 cursor--pointer">
                    {{ item.location.name }}<span v-if="item.position">, {{ item.position }}</span>
                  </label>
                </div>
                <VSpacer />
                <VCol class="pa-0 flex-grow-0">
                  <VProgressCircular
                    v-if="item.progress > 0"
                    rotate="270"
                    :size="36"
                    :width="2"
                    :value="item.progress"
                    color="primary"
                    data-test="item-level-count"
                  >
                    <span class="text--black">{{ item.level }}</span>
                  </VProgressCircular>
                </VCol>
              </div>
              <div
                v-else
                class="d-flex align-center cursor--pointer"
                data-test="content-link"
                @click="$router.push(`/content/${item.item.task.id}`)"
              >
                <VAvatar
                  color="tt-light-mono-8"
                  size="36"
                >
                  <VIcon size="20">
                    {{ taskIconMap[item.item.task.type] }}
                  </VIcon>
                </VAvatar>
                <div class="ml-3">
                  <TextTemplate
                    :prefix="taskTypeTranslate['POOL']"
                    :show-prefix="item.item.task.type ===taskType['POOL']"
                    :text="item.item.task.name"
                  />
                  <label class="tt-black--text text--lighten-2 cursor--pointer">
                    {{ generateSubtitle(item) }}
                  </label>
                </div>
              </div>
            </template>
            <template #item.role="{item}">
              <div v-if="item.type==='staff'">
                <div
                  v-for="role in item.role"
                  :key="role"
                >
                  {{ role }}
                </div>
              </div>
              <div v-if="item.type==='content'">
                Контент
              </div>
            </template>
            <template #item.activity="{item}">
              <div v-if="item.type==='staff' && formatDate(item.last_request) !== '01.01.1'">
                {{ formatDate(item.last_request) }}
              </div>
              <div v-else>
                —
              </div>
            </template>
            <template #item.created="{item}">
              <div class="d-flex align-start justify-space-between">
                <div v-if="item.type==='staff'">
                  {{ formatDate(item.created) }}
                </div>
                <div v-else>
                  —
                </div>
                <TTTooltip bottom>
                  <template #activator="{ on }">
                    <TTBtn
                      fab
                      small
                      :loading="activeUserLoading && loadingId === item.id"
                      :disabled="activeUserLoading"
                      depressed
                      class="ml-4"
                      color="transparent tt-ghost--text"
                      data-test="button-staff-edit"
                      @click.stop="toggleEdit(item)"
                      v-on="on"
                    >
                      <VIcon size="19">
                        fal fa-pencil
                      </VIcon>
                    </TTBtn>
                  </template>
                  <span>Редактировать</span>
                </TTTooltip>
              </div>
            </template>
          </TTDataTable>
        </VCol>
      </VRow>
    </template>
  </VContainer>
</template>

<script>
import { formatDate } from '@/utils';
import * as snamiApi from '@/services/backend/snami';
import StaffForm from '@/components/forms/StaffForm.vue';
import ReplaceStaffForm from '@/components/forms/ReplaceStaffForm.vue';
import TaskContentForm from '@/components/forms/TaskContentForm.vue';
import NewSAvatar from '@/components/ui/NewSAvatar.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import { taskIconMap, taskType, taskTypeTranslate } from '@/constants';
import TextTemplate from '@/components/shared/TextTemplate.vue';

export default {
  name: 'StaffTabNetwork',
  components: {
    TextTemplate,
    DialogWrapper,
    StaffForm,
    TaskContentForm,
    ReplaceStaffForm,
    NewSAvatar,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      taskIconMap,
      taskType,
      taskTypeTranslate,
      loading: false,
      loadingId: null,
      networkSrc: [],
      tableData: [],
      task: {},
      level: {},
      filterBy: ['Все роли'],
      filter: 'Все роли',
      staffDialog: false,
      replaceDialog: false,
      contentDialog: false,
      activeEntity: {},
      activeUserLoading: false,
      wasSaved: false,
      headers: [
        {
          text: 'Сотрудник',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Роль',
          value: 'role',
          sortable: false,
          width: '200px',
        },
        {
          text: 'Активность',
          value: 'activity',
          sortable: false,
          width: '140px',
        },
        {
          text: 'Добавлен',
          value: 'created',
          sortable: false,
          width: '200px',
        },
      ],
    };
  },
  computed: {
    staffId() {
      return this.data.id;
    },
    network() {
      if (this.filter === 'Все роли') {
        return this.tableData;
      }
      if (this.filter === 'Контент') {
        return this.tableData.filter((item) => item.type === 'content');
      }
      return this.tableData.filter((item) => item.type === 'staff' && item.role.includes(this.filter));
    },
  },
  created() {
    this.getData();
  },
  methods: {
    formatDate,
    generateSubtitle(item) {
      const titles = [
        item?.item?.location?.name || null,
        item?.item?.task?.level?.name || null,
      ];
      return titles.filter((title) => title !== null).join(', ');
    },
    generateTitle(item) {
      return `Опрос • ${item.item.task.name}`;
    },
    handleReplace(replaceId) {
      if (replaceId) {
        this.replaceDialog = false;
        this.getData();
      }
    },
    toggleEdit(item) {
      this.loadingId = item.id;
      if (item.type === 'staff') {
        this.toggleEditStaff();
      }
      if (item.type === 'content') {
        this.toggleEditContent();
      }
    },
    toggleEditStaff() {
      this.activeUserLoading = true;
      this.$repositories.staff.list({ data: { filter: { id: [this.loadingId] } } }).then((r) => {
        const { data } = r.data;
        // eslint-disable-next-line prefer-destructuring
        this.activeEntity = data[0];
        this.staffDialog = true;
      }).catch((e) => {
        console.warn(e);
      }).finally(() => {
        this.activeUserLoading = false;
      });
    },
    closeStaffModal() {
      this.activeEntity = {};
      this.staffDialog = false;
    },
    toggleEditContent() {
      this.activeUserLoading = true;
      this.$repositories.taskContent.list({ data: { filter: { id: this.loadingId } } }).then((r) => {
        const { data } = r.data;
        // eslint-disable-next-line prefer-destructuring
        this.activeEntity = data[0];
        return this.$repositories.task.list({ data: { filter: { id: data[0].task_id } } });
      })
        .then((r) => {
          const { data } = r.data;
          // eslint-disable-next-line prefer-destructuring
          this.task = data[0];
          return snamiApi.getLevel({ filter: { id: data[0].level_id } });
        })
        .then((r) => {
          const { data } = r.data;
          // eslint-disable-next-line prefer-destructuring
          this.level = data[0];
          this.contentDialog = true;
        })
        .catch((e) => {
          console.warn(e);
        })
        .finally(() => {
          this.activeUserLoading = false;
        });
    },
    closeContentModal() {
      this.$root.$emit('modalTaskContentFormWillClose', {
        wasSaved: this.wasSaved,
      });
      this.activeEntity = {};
      this.task = {};
      this.level = {};
      this.contentDialog = false;
    },
    handleSave() {
      this.wasSaved = true;
      this.closeContentModal();
      this.closeStaffModal();
      this.getData();
    },
    getData() {
      this.loading = true;
      this.$repositories.staff.getNetwork(this.staffId).then((r) => {
        const { data } = r.data;

        this.tableData = [];
        this.filterBy = ['Все роли'];
        data.subordinate.forEach((item) => {
          this.tableData.push({
            ...item,
            id: item.staff.id,
            type: 'staff',
          });
          item.role.forEach((role) => {
            if (!this.filterBy.includes(role)) {
              this.filterBy.push(role);
            }
          });
        });
        // eslint-disable-next-line no-unused-expressions
        data.content && data.content.forEach((item) => {
          this.tableData.push({
            ...item,
            id: item.item.id,
            type: 'content',
          });
          if (!this.filterBy.includes('Контент')) {
            this.filterBy.push('Контент');
          }
        });
      }).catch((e) => {
        console.warn(e);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
  .staff-tab-network .staff-tab-network__select {
    width: 100%;
  }

  .filter-role.v-input .v-input__slot:before{
    display: none!important;
  }
</style>
