<template>
  <div>
    <TTDataTable
      :items="parsedData"
      :headers="headers"
      hide-default-footer
      data-test="staff-result-table"
      :items-per-page="-1"
    >
      <template #item="{item}">
        <tr>
          <td>
            <p
              class="ma-0"
              data-test="staff-result-table-item-date"
            >
              {{ $dayjs(item.date_time).format('DD.MM.YYYY') }}
            </p>
            <p
              class="ma-0"
              data-test="staff-result-table-item-time"
            >
              {{ $dayjs(item.date_time).format('HH:mm') }}
            </p>
          </td>
          <td :key="item.task_id">
            <div v-if="getTask(item.task_id)">
              <SExpandable :max-lines="2">
                <RouterLink
                  :to="`/content/${getTask(item.task_id).id}`"
                  class="text-decoration-none"
                >
                  <p
                    class="text--black"
                    data-test="staff-result-table-item-name"
                  >
                    {{ item.name }}
                  </p>
                </RouterLink>
              </SExpandable>
              <p
                class="tt-black--text text--lighten-2"
                data-test="staff-result-table-item-type"
              >
                {{ contentTypeText[item.content_type] }}
              </p>
            </div>
          </td>
          <td>
            <p data-test="staff-result-table-item-question">
              {{ item.meta.question }}
            </p>
          </td>
          <td
            :key="item.id"
            data-test="staff-result-table-item-answer"
          >
            <StaffTabAnswers
              :type="item.content_type"
              :meta="item.meta"
              :value-int="item.value_int"
              :value-str="item.value_str"
              :is-correct="item.is_correct"
            />

            <p
              v-if="item.content_type === 'RATE' || item.content_type === 'YES_NO'"
              class="mb-0"
            >
              <SExpandable
                :max-lines="2"
              >
                {{ item.value_str }}
              </SExpandable>
            </p>
          </td>
        </tr>
      </template>
    </TTDataTable>
  </div>
</template>

<script>
import find from 'lodash/find';
import StaffTabAnswers from '@/components/staff/StaffTabAnswers.vue';
import { contentTypeText } from '@/constants';
import SExpandable from '@/components/ui/SExpandable.vue';

export default {
  name: 'StaffTabResults',
  components: { StaffTabAnswers, SExpandable },
  props: {
    data: {
      type: Array,
      required: true,
    },
    tasks: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      contentTypeText,
      headers: [
        {
          text: 'Дата',
          sortable: false,
          value: 'data',
          width: 120,
        },
        {
          text: 'Задача',
          sortable: false,
          value: 'task',
          width: 220,
        },
        {
          text: 'Вопрос',
          sortable: false,
          value: 'question',
          width: '30%',
        },
        {
          text: 'Ответ',
          value: 'answer',
          sortable: false,
          width: '30%',
        },
      ],
    };
  },
  computed: {
    parsedData() {
      return this.data.map(({ meta, ...rest }) => ({
        ...rest,
        meta: JSON.parse(meta),
      }));
    },
  },
  methods: {
    getTask(id) {
      return find(this.tasks, (i) => i.id === id);
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
